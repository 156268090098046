<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between mb-4">
          <b-button
            v-if="hasDeletedUser"
            class="mb-4"
            variant="warning"
            @click="prepareDeletedLink()"
            >{{ $t("ROUTES.DELETED_USERS") }}</b-button
          >
          <b-button class="mb-4" variant="primary" @click="prepareUserLink()">
            {{ $t("ROUTES.NEW_USER") }}
          </b-button>
        </div>

        <DatePickers
          :filters="filters"
          @option-selected="onOptionSelected"
          @on-start-date-selected="onStartDateSelected"
          @on-end-date-selected="onEndDateSelected"
          @on-all-dates-selected="onAllDatesSelected"
        />

        <ListSearch @filter-change="onFilterChange" />

        <b-table
          ref="userTable"
          id="users-table"
          primary-key="_id"
          :items="fetchData"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterIncludedFields"
          selectable
          select-mode="single"
          :busy.sync="isBusy"
          :per-page="perPage"
          :current-page="currentPage"
          @row-selected="onRowSelected"
        >
          <template #table-busy>
            <div class="text-center text-danger my-5">
              <b-spinner class="align-middle busy-spinner"></b-spinner>
            </div>
          </template>
        </b-table>

        <div class="mt-5 col-12 pt-5">
          <b-pagination
            align="fill"
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="orders-table"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { mapActions } from "vuex";
import ListSearch from "@/components/list/ListSearch.vue";
import DatePickers from "@/components/list/DatePickers.vue";
import { ListMixin } from "@/mixins/list.mixin";

export default {
  name: "AdminList",
  components: { ListSearch, DatePickers },
  mixins: [ListMixin],
  data() {
    return {
      items: [],
      hasDeletedSubCompany: 0,
      hasDeletedUser: 0,
      filterIncludedFields: ["firstName", "lastName"],
      filters: [],
      fields: [
        {
          key: "_id",
          label: "ID",
          sortable: true
        },
        {
          key: "fullName",
          label: this.$t("FORM.FULL_NAME"),
          sortable: true
        },
        {
          key: "email",
          label: this.$t("FORM.EMAIL"),
          sortable: true
        },
        {
          key: "phone",
          label: this.$t("FORM.PHONE"),
          sortable: true,
          class: "text-center"
        },
        {
          key: "createdAt",
          label: this.$t("LIST.CREATED_AT"),
          sortable: true,
          class: "text-center",
          formatter: value => {
            return this.$dateTimeFilter(value, "date-time");
          }
        }
      ]
    };
  },
  computed: {
    fetchUrl() {
      return `admins`;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t("ROUTES.USERS") }
    ]);
  },
  methods: {
    ...mapActions({
      setSelectedAdmin: "admin/SET_SELECTED_USER",
      clearSelectedAdmin: "admin/CLEAR_SELECTED_USER"
    }),
    async onRowSelected(item) {
      const admin = item[0];
      this.prepareUserLink(admin);
    },
    prepareUserLink(user) {
      const action = user ? this.setSelectedAdmin : this.clearSelectedAdmin;
      action(user);
      this.$router.push({
        name: "admin",
        query: { isAdmin: true }
      });
    },
    prepareDeletedLink() {
      this.$router.push({ name: "settings-users-deleted" });
    }
  }
};
</script>

<style lang="scss" scoped>
.table td {
  padding: 0.5rem;
  vertical-align: middle;
}
</style>
